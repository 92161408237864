<template>
  <div class="">
    <div class="row page-title">
      <label>New Client Request</label>
    </div>
    <div class="row stepper-outer">
      <div class="col-lg-10 mx-auto chevron-steps-container">
        <chevron-steps :steps="tabs" @click="navigate"></chevron-steps>
      </div>
    </div>
    <div class="pt-30 row">
      <div class="col-lg-12">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
const ClientInfo = () => import("../components/Client/ClientInfo");
const BillingInfo = () => import("../components/Client/BillingInfo");
const EulerGrade = () => import("../components/Client/EulerGrade");
const CommissionInfo = () => import("../components/Client/CommissionInfo");
const ProductInfo = () => import("../components/Client/ProductInfo");
const Review = () => import("../components/Client/Review");
import { mapGetters } from "vuex";
export default {
async mounted() {
    this.$store.commit("client/reset");
    window.localStorage.setItem("UploadfileId", "");
    if (!this.CanAccessList) {
      this.$router.push({
        name: "unauthorized",
        params: { message: "UnAuthorized" },
      });
    } else if (!this.CanCreateClientRequest) {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    } else {
      let id = this.id;
      if (id) {
        if (this.isDraft == true) {
          this.$store.dispatch("client/getClientDraftData", parseInt(id));
        } else {
          await this.$store.dispatch("client/getClientFormData", parseInt(id))
            .catch((err) => {
              if (err.response && err.response.status == 403) {
                this.$router.push({
                  name: "error",
                  params: {
                    message: "UnAuthorized"
                  },
                });
              }
              else {
                this.Error = true;
                alert(err);
              }
            });
        }
      }
      this.$emit("can-continue", { value: true });
      this.$on("stepChanged", (tabIndex) => {
        this.UpdateSteps(tabIndex);
      });
      this.$on("stepInitiated", (tabIndex) => {
        this.InProgressStep(tabIndex);
      });
      this.$on("billingPartyChanged", (timeOnly) => {
        this.addOrRemoveCommissionSection(timeOnly);
      });
      this.$store.dispatch("client/getPaymentTerms");
    }
  },
  methods: {
    addOrRemoveCommissionSection(timeOnly) {
      if (timeOnly == true) {
        this.$store.commit("client/RemoveAllCommission");
        let commissionTabIndex = this.tabs.findIndex((s) => s.name == "4");
        if (commissionTabIndex > -1) {
          this.tabs.splice(commissionTabIndex, 1);
        }
      } else {
        let commissionTabIndex = this.tabs.findIndex((s) => s.name == "4");
        if (commissionTabIndex < 0) {
          this.tabs.splice(3, 0, {
            icon: "4",
            name: "4",
            description: "Commission/Fee Info",
            route: "/Request/Client/CommissionInfo",
            component: CommissionInfo,
            state: this.id ? "complete" : "",
          });
        }
      }
    },
    UpdateSteps: function (index) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i <= index || index == this.tabs.length - 1) {
          this.$set(this.tabs[i], "state", "complete");
          this.completedStep =
            this.id == null || parseInt(this.id) <= 0 || this.isDraft == true
              ? i
              : 4;
        }
      }
    },
    InProgressStep: function (index) {
      for (var i = 0; i < this.tabs.length; i++) {
        if (i == index) {
          this.$set(this.tabs[i], "state", "in progress");
        } else if (i <= this.completedStep) {
          this.$set(this.tabs[i], "state", "complete");
        } else if (this.tabs[i].state != "complete") {
          this.$set(this.tabs[i], "state", "");
        }
      }
      if (this.completedStep < 0 && index > 0) {
        if (this.id) {
          this.$router.push({
            path: `${this.tabs[0].route}/${parseInt(this.id)}`,
          });
        } else {
          this.$router.push({
            path: this.tabs[0].route,
          });
        }
      }
    },
    enableNext: function () {
      this.$emit("can-continue", { value: true });
    },
    navigate(index) {
      let currentRouteIndex = this.$route.meta.tabIndex;
      if (index > this.completedStep + 1 && index > currentRouteIndex) {
        //this.$store.dispatch("progress/setErrorMessage","Please complete previous steps to proceed!");
        // this.$root.toastDanger("check color");
      } else {
        if (this.id) {
          this.$router.push(`${this.tabs[index].route}/${this.id}`);
        } else {
          this.$router.push(this.tabs[index].route);
        }
      }
    },
    SetBillingInfoTabStatus(){
      const setTabState = (state) => this.$set(this.tabs[2], "state", state);
      if (this.completedStep > 1) {
        const inProgressCondition = this.BillingInfo.ShowAORFields;
        setTabState(inProgressCondition ? "in progress" : "complete");
      }
    }
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    localStorage.setItem('clienticId',null);
    this.$store.commit("client/reset");
    if (this.id && this.isDraft != true) {
      this.completedStep = 4;
    }
  },
  activated() {
    if (!this.CanAccessList) {
      this.$router.push({
        name: "unauthorized",
        params: { message: "UnAuthorized" },
      });
    } else if (!this.CanCreateClientRequest) {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    }
  },
  data() {
    return {
      completedStep: -1,
      tabs: [
        {
          icon: "1",
          name: "1",
          description: "Client Info",
          component: ClientInfo,
          route: "/Request/Client/ClientInfo",
          state: this.id && this.isDraft != true ? "complete" : "in progress",
        },
        {
          icon: "2",
          name: "2",
          description: "Euler Grade",
          route: "/Request/Client/EulerGrade",
          component: EulerGrade,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "3",
          name: "3",
          description: "Billing Info",
          route: "/Request/Client/BillingInfo",
          component: BillingInfo,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "4",
          name: "4",
          description: "Commission/Fee Info",
          route: "/Request/Client/CommissionInfo",
          component: CommissionInfo,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "5",
          name: "5",
          description: "Product Info",
          route: "/Request/Client/ProductInfo",
          component: ProductInfo,
          state: this.id && this.isDraft != true ? "complete" : "",
        },
        {
          icon: "6",
          name: "6",
          description: "Review",
          route: "/Request/Client/Review",
          component: Review,
          state: "",
        },
      ],
    };
  },
  watch: {
    "clientinfopageone.Entity": function () {
        this.SetBillingInfoTabStatus()
    },
    "BillingInfo.ShowAORFields": function () {
        this.SetBillingInfoTabStatus()
    },
    "BillingInfo.BillingParty": function () {
      let timeOnlyOptions = [
        "Time Only (Client Related Time MED)",
        "Time Only (Internal Project Related Time ADM)",
      ];
      let timeOnlySelected = timeOnlyOptions.includes(
        this.BillingInfo.BillingParty
      );
      this.$emit("billingPartyChanged", timeOnlySelected);
    },
  },
  computed: {
    ...mapGetters("client", {
      BillingInfo: "ClientBillingInformations",
      clientinfopageone: "ClientBasicDetails",
    }),
    CanCreateClientRequest() {
      return this.$store.getters.canCreateClient;
    },
    CanAccessList() {
      return this.$store.getters.canAccessList;
    },
  },
};
</script>

<style scoped>
.stepper-box,
.stepper-box .top .steps-wrapper .step .circle {
  background-color: transparent !important;
}

.content-holder {
  background-color: #ffffff !important;
}
.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}
.progress-bar[data-v-ef603d5c]:not(.hide-number):before {
  width: 30px !important;
  height: 30px !important;
  padding-top: 6px !important;
}
</style>
